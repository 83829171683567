import { Grid } from "@mui/material";
import styles from "./index.module.css";

import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import HoverImage from "../HoverImage";
import { Parallax } from "react-parallax";

const MediaImage = () => {
  return <PlayCircleIcon sx={{ fontSize: 100, color: "red" }} />;
};

const Media = () => {
  return (
    <Parallax
      blur={3}
      bgImage="./img/fatcaps.png"
      bgImageAlt="Media"
      strength={300}
    >
      <div className={styles.container}>
        <Grid container xs={10} spacing={2}>
          <Grid item xs={12}>
            <h2>Media</h2>
          </Grid>
        </Grid>
        <Grid container xs={10} spacing={2}>
          <Grid item xs={10}>
            <HoverImage component={<MediaImage />}>
              <img
                src="https://img.youtube.com/vi/avls74kzly8/maxres3.jpg"
                alt="Graffiti"
                style={{
                  width: "100%",
                  height: 400,
                  padding: 0,
                  margin: 0,
                  objectFit: "cover",
                }}
              />
            </HoverImage>
          </Grid>
        </Grid>
        <Grid container xs={10} spacing={2} mt={1}>
          <Grid item xs={5}>
            <HoverImage component={<MediaImage />}>
              <img
                src="https://img.youtube.com/vi/RBGkyLPVLfU/maxres2.jpg"
                alt="Graffiti"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  padding: 0,
                  margin: 0,
                  objectFit: "cover",
                }}
              />
            </HoverImage>
          </Grid>
          <Grid item xs={5}>
            <HoverImage component={<MediaImage />}>
              <img
                src="https://img.youtube.com/vi/CiejnCicImY/maxres2.jpg"
                alt="Graffiti"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  padding: 0,
                  margin: 0,
                  objectFit: "cover",
                }}
              />
            </HoverImage>
          </Grid>
        </Grid>
      </div>
    </Parallax>
  );
};

export default Media;
