import { Route, Routes } from 'react-router';
import { AgencyScreen, HomeScreen, MediaScreen } from './screens';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<HomeScreen />} />
        <Route path="agency" element={<AgencyScreen />} />
        <Route path="media" element={<MediaScreen />} />
      </Routes>
    </div>
  );
}

export default App;
