import { Grid } from "@mui/material";
import styles from "./index.module.css";

const TopBar = () => {
  return (
    <div className={styles.container}>
      <Grid container style={{ flex: 1, justifyContent: "space-between" }}>
        <Grid
          item
          xs={4}
          style={{ display: "flex" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          Tel: 33-1164-5452
        </Grid>
        <Grid
          item
          xs={4}
          style={{ display: "flex" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          spraymafia@outlook.es
        </Grid>
      </Grid>
    </div>
  );
};

export default TopBar;
