import { Grid } from "@mui/material";
import styles from "./index.module.css";
import HoverImage from "../HoverImage/index";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";

const AgencyImage = ({ title, subtitle, main = false }) => {
  return (
    <div>
      <h2 style={{ textAlign: "center", fontSize: main ? 48 : 30 }}>{title}</h2>
      {/* <div style={{ textAlign: "center" }}>{subtitle}</div> */}
    </div>
  );
};

const Agency = () => {
  return (
    <div className={styles.container}>
      <Grid container xs={10} spacing={4}>
        <Grid item xs={5}>
          <h2 style={{ margin: 0 }}>Agencia</h2>
          <p style={{ textAlign: "justify" }}>
            Somos una promotora de Graffiti y Arte. Impulsamos la colocación de
            talentos nuevos, generamos productos desde nuestra experiencia en el
            Graffiti y mostramos los estilos de diferentes escritores desde
            nuestras plataformas. Agencia ° Tienda ° Media
          </p>
          <h3>Visión</h3>
          <p style={{ textAlign: "justify" }}>
            Mostrar el mundo del Graffiti y sus códigos al mundo entero de
            manera real.
          </p>
          <h3>Misión</h3>
          <ul>
            <li>Acercar artistas a los mejores eventos</li>
            <li>Vestirlos con el Graffiti que aprendimos</li>
            <li>Mostrarlos al mundo desde nuestros medios</li>
          </ul>
          <h3>Contrataciones</h3>
          <WhatsAppIcon sx={{ fontSize: 40, color: "#25D366" }} />
          <InstagramIcon sx={{ fontSize: 40, color: "#FD1D1D" }} />
        </Grid>
        <Grid item xs={7}>
          <HoverImage component={<AgencyImage title={"Hades"} main />}>
            <img
              src="./img/hades_1.jpeg"
              alt="Hades"
              style={{
                width: "100%",
                height: 400,
                padding: 0,
                margin: 0,
                objectFit: "cover",
              }}
            />
          </HoverImage>
        </Grid>
      </Grid>
      <Grid container xs={10} spacing={2} mt={1}>
        <Grid item xs={4}>
          <HoverImage component={<AgencyImage title={"Reor"} />}>
            <img
              src="./img/reor_1.jpg"
              alt="Reor"
              style={{
                width: "100%",
                height: 200,
                padding: 0,
                margin: 0,
                objectFit: "cover",
              }}
            />
          </HoverImage>
        </Grid>
        <Grid item xs={4}>
          <HoverImage component={<AgencyImage title={"Trepo Parker"} />}>
            <img
              src="https://cdn2.telediario.mx/uploads/media/2022/12/23/trepo-parker-murales-rusia-especial.jpg"
              alt="Trepo Parker"
              style={{
                width: "100%",
                height: 200,
                padding: 0,
                margin: 0,
                objectFit: "cover",
              }}
            />
          </HoverImage>
        </Grid>
        <Grid item xs={4}>
          <HoverImage component={<AgencyImage title={"Jewl"} />}>
            <img
              src="./img/jewl_1.jpg"
              alt="Jewl"
              style={{
                width: "100%",
                height: 200,
                padding: 0,
                margin: 0,
                objectFit: "cover",
                objectPosition: "center top",
              }}
            />
          </HoverImage>
        </Grid>
      </Grid>
    </div>
  );
};

export default Agency;
