import { Grid, TextField, Button, Typography } from "@mui/material";
import {
  LocationOn,
  Email,
  Phone,
  Twitter,
  Facebook,
  Instagram,
} from "@mui/icons-material";
import styles from "./index.module.css";

const Contact = () => {
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={styles.contactContainer}
      spacing={4}
    >
      <Grid item xs={12} md={5} className={styles.contactInfo}>
        <Typography variant="h5" component="h3" className={styles.contactTitle}>
          Contacto
        </Typography>
        <Typography paragraph className={styles.contactDescription}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam nulla
          enim, consectetur sollicitudin nibh nec, elementum volutpat metus.
          Fusce interdum interdum tortor, a porttitor libero.
        </Typography>

        <div className={styles.contactDetails}>
          <LocationOn />
          <Typography variant="body2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
        </div>
        <div className={styles.contactDetails}>
          <Email />
          <Typography variant="body2">email@example.com</Typography>
        </div>
        <div className={styles.contactDetails}>
          <Phone />
          <Typography variant="body2">+123 456 7890</Typography>
        </div>

        <div className={styles.socialIcons}>
          <Twitter />
          <Facebook />
          <Instagram />
        </div>
      </Grid>

      <Grid item xs={12} md={4} className={styles.contactForm}>
        <form noValidate autoComplete="off">
          <TextField
            fullWidth
            label="Nombre"
            variant="filled"
            className={styles.textField}
            style={{ marginBottom: "15px" }}
          />
          <TextField
            fullWidth
            label="Teléfono"
            variant="filled"
            className={styles.textField}
            style={{ marginBottom: "15px" }}
          />
          <TextField
            fullWidth
            label="Email"
            variant="filled"
            className={styles.textField}
            style={{ marginBottom: "15px" }}
          />
          <TextField
            fullWidth
            label="Mensaje"
            variant="filled"
            multiline
            rows={4}
            className={styles.textField}
            style={{ marginBottom: "15px" }}
          />
          <Button variant="contained" className={styles.buttonSubmit}>
            Enviar
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default Contact;
