import React, { useEffect, useState } from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { Box, Grid } from "@mui/material";
import TopBar from "../components/TopBar";
import Menu from "../components/Menu";
import Streetwear from "../components/Streetwear";
import Media from "../components/Media";
import Agency from "../components/Agency";
import axios from "axios";
import { API_URL } from "../constants";
import Contact from "../components/Contact";

const spanStyle = {
  background: "rgba(0, 0, 0, .6)",
  color: "white",
  height: "30px",
  width: "100%",
  fontSize: "20px",
  textAlign: "center",
  fontWeight: "bold",
};

const divStyle = {
  display: "flex",
  // alignItems: "center",
  // justifyContent: "center",
  alignItems: "flex-end",
  justifyContent: "center",
  backgroundSize: "cover",
  // height: "250px",
  height: "500px",
};

const HomeScreen = () => {
  const [slideImages, setSlideImages] = useState([]);

  const getImages = async () => {
    const response = await axios.get(`${API_URL}/api/sliders?populate=image`);

    const images = response.data.data.map((slider) => {
      const { image, label, position } = slider.attributes;
      const { url } = image.data.attributes;
      console.log("position", position);
      return {
        url: `${API_URL}${url}`,
        caption: label,
        backgroundPosition: position ? position : "center",
      };
    });

    setSlideImages(images);
  };

  useEffect(() => {
    getImages();
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container>
        <Grid item xs={12}>
          <TopBar />
        </Grid>
        <Grid item xs={12}>
          <Menu />
        </Grid>
        <Grid item xs={12}>
          <div className="slide-container">
            {slideImages.length && (
              <Slide>
                {slideImages?.map((image, index) => (
                  <div key={index}>
                    <div
                      style={{
                        ...divStyle,
                        backgroundImage: `url(${image.url})`,
                        backgroundPosition: image.backgroundPosition,
                      }}
                    >
                      <div style={spanStyle}>{image.caption}</div>
                    </div>
                  </div>
                ))}
              </Slide>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Streetwear />
      </Grid>
      <Grid item xs={12}>
        <Media />
      </Grid>
      <Grid item xs={12} mt={3}>
        <Agency />
      </Grid>
      <Grid item xs={12}>
        <Contact />
      </Grid>
    </Box>
  );
};
export default HomeScreen;
